<template>
    <b-col sm="12">
        <!-- Main Container -->
        <b-card id="files" :class="localFiles.length === 0 ? 'center-upload' : ''"
                :style="height !== undefined ? 'height:' + height * 1.1 + 'px;' : 'height: 330px;'" no-body>
            <div v-if="localFiles.length === 0"
                 style="margin-top: 10px">
                <input type="file" ref="files" @change="handleFileUpload()" multiple style="width: 97px"/>
            </div>
            <div v-else style="display: flex; justify-content: space-between;">
                <div @click="localFiles = []; localFileNames = [];">
                    <fa-icon :icon="['fas', 'backward']" style="margin-left: 3px; cursor: pointer;"/>
                    <span style="cursor: pointer;"> Go Back</span>
                </div>
                <div v-if="selfUpload === true">
                    <b-button size="sm" @click="submitFiles" :disabled="uploadStatus !== ''" variant="success" style="text-align: right; padding-right: 0"><b>Upload Files </b>
                        <fa-icon :icon="['fas', 'forward']" style="margin-right: 3px; cursor: pointer;"/>
                    </b-button>
                </div>
            </div>

            <div v-if="localFiles.length > 0" style="width: 100%; overflow-y: auto;">

                <!-- Upload Status -->
                <div v-if="uploadStatus !== ''" style="background-color: rgba(0,0,0,.8);width: 100%; z-index: 900; position: absolute">
                    <div class="rounded" style="padding: 15px">
                        <fa-icon :icon="['fas', 'spinner']" spin size="2x" style="margin-right: 10px;"/>
                        <span><b>{{ uploadStatus }}...</b></span>
                    </div>
                </div>

                <!-- File Image Body -->
                <div class="card-grid"
                     style="display: flex; justify-content: center; align-items: center;" :style="vertical ? 'flex-direction: column':''">
                    <b-card v-for="(file, index) in localFiles" :key="index" class="profile-card" :style="height !== undefined ? 'width:' + height * .67 + 'px;' : 'width: 180px;'" no-body>
                        <div class="text-content">
                            {{ file.name }}
                        </div>
                        <div class="image-parent" :style="height !== undefined ? 'height:' + height*.8 + 'px;' : 'height: 240px;'">
                            <img v-if="checkURL(localFiles[index]) === 1" class="image-resize"
                                 :src="makeUrl(localFiles[index])" alt="">
                            <b-embed v-else-if="checkURL(localFiles[index]) === 2" class="image-resize"
                                     :src="makeUrl(localFiles[index])" allowfullscreen/>
                            <div v-else class="d-flex w-100 h-100 justify-content-center align-items-center"
                                 style="background: #20a8d8">
                                <fa-icon :icon="['fas', 'file']" size="lg"/>
                            </div>
                        </div>
                        <div class="text-content">
                            <b-input size="sm" :placeholder="newNames === true? 'File Name':'File Description'"
                                     style="border-top-left-radius: 0; border-top-right-radius: 0;"
                                     :formatter="characterLimit"
                                     v-model="localFileNames[index]"></b-input>
                        </div>
                    </b-card>
                </div>
            </div>
        </b-card>
    </b-col>
</template>

<script>
export default {
    name: "ReportFileUpload",
    props: {
        files: [FileList, Array],
        fileNames: Array,
        uploadStatus: String,
        getFiles: Boolean,
        height: Number,
        newNames: {
            type: Boolean,
            default: true
        },
        vertical: {
            type: Boolean,
            default: false
        },
        selfUpload: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localFiles: [],
            localFileNames: [],
        }
    },
    created() {
        this.localFiles = this.files;
        this.localFileNames = this.fileNames;
    },
    methods: {
        handleFileUpload() {
            this.localFiles = this.$refs.files.files;
        },
        checkURL(file) {
            if (file.type.includes('image')) {
                return 1;
            } else if (file.type.includes('video')) {
                return 2;
            } else {
                return null;
            }
        },
        makeUrl(file) {
            return URL.createObjectURL(file);
        },
        submitFiles() {
            this.$emit('returnFiles', {files: this.localFiles, fileNames: this.localFileNames})
        },
        characterLimit(e) {
            //this limits the max characters inside an address
            return String(e).substring(0, 42);
        },
    },
    watch: {
        files() {
            this.localFiles = this.files;
        },
        fileNames() {
            this.localFileNames = this.fileNames;
        },
        getFiles() {
            this.submitFiles();
        },
        uploadStatus() {
            if (this.uploadStatus === '') {
                this.localFileNames = this.localFiles = [];
            }
        },
    }
}
</script>

<style scoped>
.card-grid {
    min-width: fit-content;
    display: flex;
    overflow-y: auto;
    padding: 5px;
    background: #070707;
    object-fit: contain;

}

.profile-card {
    background: #282828;
    display: flex;
    flex-direction: column;
    margin: 0 5px 5px 5px !important;
}

.text-content {
    text-align: center;
    font-weight: bold;
    width: 100%;
}

.image-parent {
    border: 1px solid #23282c;
    flex-grow: 1;
    background: black;
    display: flex;
    align-Items: center;
    justify-content: center;
}

.image-resize {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.center-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #070707;
}
</style>