<template>
    <div>
            <!--Confirm Case Number Modal-->
        <b-modal id="confirm-name" title="Confirm Case Number" class="modal-dark">
            <b-form-group>
                <label>Type case number '{{ caseNumber }}' to confirm:</label>
                <b-form-input v-model="confirmCaseNumber" size="sm"/>
            </b-form-group>
            <template slot="modal-footer">
                <b-button @click="$bvModal.hide('confirm-name'); confirmCaseNumber = ''" size="sm" variant="secondary">
                    Cancel
                </b-button>
                <b-button @click="getFiles = !getFiles, $bvModal.hide('confirm-name'), confirmCaseNumber = ''"
                          size="sm" variant="primary"
                          :disabled="confirmCaseNumber !== caseNumber">
                    Submit
                </b-button>
            </template>
        </b-modal>
        <b-card header-tag="header">

            <!--Header-->
            <span slot="header">
                <h5 style="margin: 0"><strong>Upload</strong></h5>
            </span>

            <b-row>

                <!--Upload-->
                <FileUpload :files="uploadedData"
                            :fileNames="uploadedDataNames"
                            :uploadStatus="uploading"
                            :getFiles="getFiles"
                            @returnFiles="returnFiles"/>


                <!--Case Number-->
                <b-col sm="6">
                    <b-form-group>
                        <label>Case Number</label>
                        <b-form-input v-model="caseNumber"
                                      :class="'form-control is-' + (filledOut(caseNumber) ? 'valid' : 'invalid')"/>
                    </b-form-group>
                </b-col>

                <!--Case Type-->
                <b-col sm="6">
                    <b-form-group>
                        <label>Case Type</label>
                        <b-form-input v-model="caseType"
                                      :class="'form-control is-' + (filledOut(caseType) ? 'valid' : 'invalid')"/>
                    </b-form-group>
                </b-col>

                <!--Date-->
                <b-col sm="6">
                    <label>Date</label>
                    <date-picker v-model="date" :config="datePickerOptions"
                                 :class="'form-control is-' + (filledOut(date) ? 'valid' : 'invalid')"/>
                </b-col>

                <!--Time-->
                <b-col sm="6">
                    <label>Time</label>
                    <date-picker v-model="time" :config="timePickerOptions"/>
                </b-col>

                <!--Description-->
                <b-col sm="6" style="margin-top: 16px;">
                    <label>Description</label>
                    <b-form-textarea v-model="description" style="height: calc(100% - 27px)"></b-form-textarea>
                </b-col>

                <!--Location-->
                <b-col sm="6" style="margin-top: 16px;">
                    <label>Location</label>
                    <b-input-group class="input-group-sm" prepend="Address" style="margin-bottom: 1px">
                        <vue-google-auto-complete id="to_address"
                                                  classname="form-control"
                                                  v-model="address"
                                                  placeholder="Enter Address"
                                                  @placechanged="getAddressData"
                                                  @error="handleAutocompleteError"/>
                        <b-dropdown :text="location.active ? 'Active' : 'Inactive'" size="sm"
                                    variant="dark">
                            <b-dropdown-item @click="location.active = true">Active</b-dropdown-item>
                            <b-dropdown-item @click="location.active = false">Inactive</b-dropdown-item>
                        </b-dropdown>
                    </b-input-group>
                    <b-input-group class="input-group-sm" prepend="Apartment/Unit" style="margin-bottom: 1px">
                        <b-form-input v-model="location.address2"/>
                    </b-input-group>
                    <b-input-group class="input-group-sm" prepend="City">
                        <b-form-input v-model="location.city"/>
                        <b-input-group-append class="input-group-sm">
                            <b-input-group-text>State</b-input-group-text>
                        </b-input-group-append>
                        <b-form-select
                            :options="states"
                            size="sm"
                            v-model="location.state"/>
                        <b-input-group-append class="input-group-sm">
                            <b-input-group-text>Zip</b-input-group-text>
                        </b-input-group-append>
                        <b-form-input
                            type="number"
                            v-model.number="location.zip"/>
                    </b-input-group>
                </b-col>

                <!--Submit Button-->
                <b-col sm="12" style="margin-top: 16px;">
                    <b-button size="sm" variant="primary"
                              @click="$bvModal.show('confirm-name')"
                              :disabled="checkDisabled()" style="float: right;">
                        Submit
                    </b-button>
                </b-col>

            </b-row>
        </b-card>

    </div>
</template>

<script>
import Vue from 'vue';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import axios from 'axios';
import JsZip from 'jszip';
import VueGoogleAutoComplete from 'vue-google-autocomplete';
import digitalevidence from '../../../services/digital-evidence';
import FileUpload from '@/components/FileUpload';

export default {
    name: 'UploadPage',

    components: {
        datePicker,
        VueGoogleAutoComplete,
        FileUpload,
    },

    updated() {
    },

    data() {
        return {
            getFiles: false,
            address: '',
            caseNumber: '',
            caseType: '',
            caseTypeOptions: [],
            confirmCaseNumber: '',
            datePickerOptions: {
                format: 'MM/DD/YYYY',
            },
            timePickerOptions: {
                format: 'H:mm',
            },
            date: '',
            description: '',
            location: {
                active: true,
                address2: '',
                city: '',
                state: '',
                zip: null,
            },
            states: [
                'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA',
                'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA',
                'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND',
                'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT',
                'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY',
            ],
            time: '',
            uploadedData: [],
            uploadedDataNames: [],
            uploading: "",
        };
    },

    methods: {
        checkURL(file) {
            if (file.type.includes('image')) {
                return 1;
            } else if (file.type.includes('video')) {
                return 2;
            } else {
                return null;
                /*this.icons.keys().forEach((key, index) => {
                  this.iconKey[key].keys().forEach((type, index2) => {
                    if (file.type.includes(type)) {
                      return (this.icons[key]);
                    } else if (index + 1 === this.icons.keys().length() && index2 + 1 === this.iconKey.keys().length()) {
                      return (this.icons['other']);
                    }
                  })
                });*/
            }
        },
        checkDisabled() {
            return !this.filledOut(this.caseNumber) || !this.filledOut(this.caseType) || !this.filledOut(this.date);

        },
        confirmCaseName() {
            this.$bvModal.show('confirm-name');
        },
        async getAddressData(addressData, placeResultData) {
            let streetNumber = '';
            let route = '';
            for (let i = 0; i < placeResultData.address_components.length; i++) {
                let addressType = placeResultData.address_components[i].types[0];
                if (addressType === 'street_number') {
                    streetNumber = placeResultData.address_components[i].long_name.toUpperCase();
                }
                if (addressType === 'route') {
                    route = placeResultData.address_components[i].long_name.toUpperCase();
                }
                if (addressType === 'locality') {
                    this.location.city = placeResultData.address_components[i].long_name.toUpperCase();
                }
                if (addressType === 'administrative_area_level_1') {
                    this.location.state = placeResultData.address_components[i].short_name.toUpperCase();
                }
                if (addressType === 'postal_code') {
                    this.location.zip = parseInt(placeResultData.address_components[i].long_name);
                }
            }
            this.address = streetNumber + ' ' + route;
            document.getElementById('to_address').value = this.address;
        },
        filledOut(i) {
            return !(i === null || i === undefined || i === '');

        },
        handleAutocompleteError() {
            this.$mToast({
                title: ' Error',
                text: 'Autocomplete Not Working.',
                style: 'error',
            });
        },
        handleFileUpload() {
            this.uploadedData = this.$refs.files.files;
        },
        makeEvidenceObject(id, start) {
            const uploadFiles = new Date();
            let evidenceObject = {
                caseNumber: this.caseNumber,
                caseType: this.caseType,
                description: this.description,
                evidenceDate: new Date(this.date),
                evidenceTime: this.time,
                fileId: id,
                location: {
                    address: this.address,
                    address2: this.location.address2,
                    city: this.location.city,
                    state: this.location.state,
                    zip: this.location.zip,
                },
            };
            // send evidence object to backend
            this.uploading = "UPLOADING";
            digitalevidence.postToEvidence(evidenceObject).then(() => {
                let end = new Date();
                this.uploading = "";
                this.$mToast({
                    title: 'Success',
                    text: 'Evidence successfully uploaded.',
                    style: 'success',
                });
                this.resetFields();
            }).catch(() => {
                this.uploading = "";
                this.$mToast({
                    title: ' Error',
                    text: 'Error uploading evidence. Contact support.',
                    style: 'error',
                });
            });
        },
        makeUrl(file) {
            return URL.createObjectURL(file);
        },
        resetFields() {
            this.caseNumber = '';
            this.caseType = '';
            this.date = '';
            this.time = '';
            this.description = '';
            this.address = '';
            this.location.address2 = '';
            this.location.city = '';
            this.location.state = '';
            this.location.zip = null;
            this.uploadedData = [];
            document.getElementById('to_address').value = '';
        },
        returnFiles(data) {
            this.uploadedData = data.files;
            this.uploadedDataNames = data.fileNames;
        },
        uploadSubmit() {
            let compressing = new Date();
            this.uploading = "COMPRESSING";
            let zip = new JsZip();

            for (let i = 0; i < this.uploadedData.length; i++) {
                zip.file(this.uploadedData[i].name, this.uploadedData[i])
            }

            // generate zip file from all uploaded files
            zip.generateAsync({
                type: 'blob',
                compression: 'DEFLATE',
            }).then(result => {
                let formData = new FormData();
                formData.append('file', result);
                digitalevidence.uploadEvidence(formData).then(response => {
                    this.makeEvidenceObject(response.data, compressing);
                });
            });
        },
    },
};
</script>

<style scoped>

.card-grid {
    min-width: fit-content;
    display: flex;
    flex-flow: row nowrap;
    height: 311px;
    padding: 5px;
    background: #2f353a;
}

.card-grid::before, .card-grid::after {
    content: '';
    margin: auto;
}

.profile-card {
    background: #282828;
    height: calc(100% - 0px);
    width: 200px;
    display: flex;
    flex-direction: column;
    margin: 0 5px 5px 5px !important;
}

.text-content {
    text-align: center;
    font-weight: bold;
    width: 100%;
}

.image-parent {
    border: 1px solid #23282c;
    min-height: 0;
    flex-grow: 1;
    background: black;
    display: flex;
    align-Items: center;
    justify-content: center;
}

.image-resize {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.center-upload {
    height: 338px;
    display: flex;
    justify-content: center;
    align-items: center;
}

iframe {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
}
</style>